import {Component, OnDestroy, OnInit} from '@angular/core'
import {CheckPDFResult, MorningstarService} from '../../services/morningstar.service'
import {Subscription} from 'rxjs'
import {filter, switchMap} from 'rxjs/operators'
import {MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog'
import {WaitDialogComponent} from '../wait-dialog/wait-dialog.component'
import {Customer, CustomerService} from '../../services/customer.service'
import {HttpErrorResponse} from '@angular/common/http'
import {CdkScrollable} from '@angular/cdk/scrolling'
import {MatButton} from '@angular/material/button'

@Component({
  selector: 'spb-create-pdf-dialog',
  templateUrl: './create-pdf-dialog.component.html',
  styleUrls: ['./create-pdf-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class CreatePdfDialogComponent implements OnInit, OnDestroy {

  public statusText = ''

  public ready = false

  public pdfResult: CheckPDFResult = {status: 'MISSING'}

  private customer: Customer = {} as any

  private sub$ = new Subscription()

  constructor(
    public morningstarService: MorningstarService,
    private customerService: CustomerService,
    private dialog: MatDialog
  ) {
  }

  get corporate(): boolean {
    return this.customer.corporate
  }

  public ngOnInit(): void {
    this.statusText = 'Skapar dokument hos Morningstar. Det brukar ta lite mindre än en minut.'
    this.sub$ = this.customerService.currentCustomer$.pipe(
      filter(Boolean),
      switchMap((customer: Customer) => {
        this.customer = customer
        return this.morningstarService.pdfStatus$
      }),
      filter((res: CheckPDFResult) => res.status !== 'MISSING')
    ).subscribe({
      next: (res: CheckPDFResult) => {
        Object.assign(this.pdfResult, res)
        if (res.status === 'TIMEOUT') {
          this.statusText = 'Vi lyckades inte skapa dokumentet. Oklart varför du kan prova igen kanske.'
        }

        if (res.status === 'OK') {
          this.statusText = this.corporate ?
            'Dokumentet är klart. Företagskunder måste skiva under på papper.' :
            'Dokumentet är klart. Du kan välja att skriva ut eller skicka för digital signering'

          this.ready = true
        }

        if (res.status === 'ERROR') {
          this.statusText = 'Hoppla, nu gick det riktigt fel. Kontakta support för att gräva i det här.'
        }
      }
    })
  }

  public ngOnDestroy() {
    this.sub$.unsubscribe()
  }

  public cancel(): void {
    this.morningstarService.pdfStatus.status = 'CANCEL'
  }

  public sign(): void {
    const ref = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Skickar...',
        message: ['Vi skickar dokumentet för signering i signeringsrummet. \n Det brukar ta en tre till 15 ' +
        'sekunder eller så...']
      }
    })

    this.customerService.sendForSigning(this.pdfResult.id as string)
      .pipe()
      .subscribe({
        next: () => {
          ref.close()
          this.dialog.open(WaitDialogComponent, {
            disableClose: true,
            data: {
              title: 'Skickat',
              message: ['Kunden kan nu skriva under i signeringsrummet.'],
              close: true
            }
          })
        },
        error: (e) => {
          ref.close()
          this.dialog.open(WaitDialogComponent, {
            disableClose: true,
            data: {
              title: 'Problem med signering',
              message: this.analyzeError(e),
              close: true
            }
          })
        }
      })
  }


  private analyzeError(e: HttpErrorResponse): string[] {
    if (e.error.errorMessage.indexOf('not a valid email') !== -1) {
      return ['Det är en ogiltig e-postadress, korrigera och försök igen.']
    }
    return [
      'Dokumentet gick inte att skicka för signering.',
      'Prova att skapa dokumentet på nytt. Om problemet kvarstår, kontakta PP&IT, skicka gärna med nedanstående.',
      e.error.errorMessage]
  }
}
