<div [style.padding.px]="20">
  <h3>Radera</h3>
  <div>Handläggare: {{ data.documents[this.data.index] && data.documents[this.data.index].advisor }}</div>
  <div>
    Skapad: {{ data.documents[this.data.index] && data.documents[this.data.index].created | date: 'yyyy-MM-dd HH:mm' }}
  </div>

  <div class="margin-top-small">Är du säker på att du vill radera dokumentet?</div>

  <mat-dialog-actions>
    <button
      (click)="delete()"
      color="primary"
      mat-raised-button>Ja
    </button>
    <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
  </mat-dialog-actions>
</div>
