import {inject, Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, ReplaySubject, Subject, timer} from 'rxjs'
import {environment} from '../../environments/environment'
import {filter, first, map, switchMap, take, takeUntil, tap} from 'rxjs/operators'
import {MatDialog} from '@angular/material/dialog'
import {CreatePdfDialogComponent} from '../common/create-pdf-dialog/create-pdf-dialog.component'
import {WaitDialogComponent} from '../common/wait-dialog/wait-dialog.component'
import {Customer, CustomerService} from './customer.service'
import {PortfolioHolding} from './portfolio.service'

import {WINDOW} from '../application/data-types'

export const NAMESPACE_CORPORATE = 'sparbanken.OPP558951.mwc-planner.corporate'
export const NAMESPACE_RETAIL = 'sparbanken.OPP558951.mwc-planner.retail'

export interface MorningstarReportResult {
  benchmark?: null
  reportInfo: {
    reportHeader: {
      reportName: string
    }
    preparedFor: {
      preparedForName: string
      preparedForPhone: string
      preparedForEpostaddress: string
      preparedForPersonnummer: string
    }
    preparedBy: {
      preparedByAdvisorName1: string
    }
  }
  reportSettings?: any
  reports?: string[]
}

export interface MorningstarState {
  /**
   * We know accounts are relevant at least
   */
  accounts: MorningstarAccount[]

  /**
   * Possibly the answers to the RTQ
   */
  answers: any

  /**
   * ReportResults is the Morningstar report summary data
   * that can be both read and written.
   */
  reportResults: MorningstarReportResult

  /**
   * The portfolio selection must be reset between runs
   */
  portfolioSelection: {
    portfolioSelectionOption: string
  }

  /**
   * And this one?
   */
  portfolioSelectionAccountSelected: any[]

  /**
   * We use this to manipulate the amounts
   * in some scenaria.
   */
  proposedPortfolio: {
    // Appears to be 100 if selection is modified portfolio
    totalValue: number
    // We 'think' these are more or less holdings
    holdings: PortfolioHolding[]
  }
}

export interface MorningstarAccount {
  /**
   * The only property we care about. Was this account added from Abasec?
   * If so we should not store it further on.
   */
  abasec: boolean

  balance?: number

  id?: any

  holdings: PortfolioHolding[]

  name?: string

  totalAmount?: number

  checked?: boolean
}

export interface MorningStartLoginResult {
  accessToken: string
}

export interface CheckPDFResult {

  /**
   * Various statuses
   */
  status: 'MISSING' | 'OK' | 'TIMEOUT' | 'ERROR' | 'CANCEL'

  /**
   * The document id that is needed for further processing.
   */
  id?: string
}

export interface CreatePDFResult {

  /**
   * The ID is always present
   */
  id: string

  /**
   * An updated customer is also included
   */
  customer: Customer
}

export interface GetDocumentLinkResponse {
  // A presigned link to the document
  signedUrl: string
}


@Injectable({
  providedIn: 'root'
})
export class MorningstarService {

  public portfolioPlanner: any

  public pdfStatus: CheckPDFResult = {status: 'MISSING', id: undefined}

  public pdfStatus$ = new ReplaySubject<CheckPDFResult>(1)

  /**
   * The name space can be one of "corporate" or "retail"
   * we send the one here
   */
  public namespace$ = new BehaviorSubject<string>(NAMESPACE_RETAIL)

  private namespace = NAMESPACE_RETAIL

  private readonly httpClient = inject(HttpClient)
  private readonly dialog = inject(MatDialog)
  private readonly customerService = inject(CustomerService)
  private readonly injectedWindow = inject(WINDOW)

  public toggleNamespace(): void {
    this.namespace = this.namespace === NAMESPACE_CORPORATE ? NAMESPACE_RETAIL : NAMESPACE_CORPORATE
    this.namespace$.next(this.namespace)
  }

  public getAccessToken(): Observable<MorningStartLoginResult> {
    const url = `${environment.apiUrl}/login`
    return this.httpClient.get<MorningStartLoginResult>(url).pipe()
  }

  public getAccounts(): MorningstarAccount[] {
    const state: any = this.getPlannerState()
    return state.accounts.filter((a: any) => a)
  }

  public setHoldings(): void {
    const state: MorningstarState = this.getPlannerState()
    // Here we guess that if totalValue is set we have a real portfolio
    // This will of course break in the future.
    // Only do the distribution when value is set
    if (state.proposedPortfolio.totalValue) {
      // Take all the monies!

      const sum = state.accounts
        .reduce((amt: number, a: any) => amt + a.totalAmount, 0)
      // Set the holding amount to a fraction of all money
      state.proposedPortfolio.holdings.forEach((h: PortfolioHolding) => {
        h.amount = sum * (h.weight / 100)
      })

    }
  }

  public getAnswers(): any {
    const state = this.getPlannerState()
    return state.answers
  }

  public getReportResults(): MorningstarReportResult {
    const state = this.getPlannerState()
    return state.reportResults
  }

  public resetSelection(): void {
    const state = this.getPlannerState()
    state.portfolioSelection.portfolioSelectionOption = ''
    state.portfolioSelectionAccountSelected = []
  }

  /**
   * Send the data to Morningstar via our backend. It takes
   * a while (about 1 minute) so we start to poll for the result.
   * @param data - This is something we get from Morningstar, we just send it as is.
   */
  public sendForPdf(data: any): void {
    // This is used to stop polling for reasons.
    const cancel$ = new Subject<boolean>()

    const url = `${environment.apiUrl}/documents`
    this.dialog.open(CreatePdfDialogComponent, {
      disableClose: true
    })

    this.pdfStatus$.next({status: 'MISSING'})
    this.pdfStatus = {status: 'MISSING'}
    this.customerService.currentCustomer$.pipe(
      first(),
      filter(Boolean),
      switchMap((customer: Customer) => {
        const body = {
          customerId: customer.id,
          data
        }
        return this.httpClient.put<CreatePDFResult>(url, body)
      }),
      switchMap((res: CreatePDFResult) => {
        this.pdfStatus.id = res.id
        this.customerService.currentCustomer$.next(res.customer)
        this.customerService.getCustomers().subscribe()
        return this.checkForDocument(res.id) // This will emit every n seconds
      }),
      map((res: CheckPDFResult) => {
        if (this.pdfStatus.status === 'CANCEL') {
          cancel$.next(true)
        }
        this.pdfStatus.status = res.status
        this.pdfStatus$.next(this.pdfStatus)
        return res
      }),
      takeUntil(cancel$)
    ).subscribe({
      next: (res: CheckPDFResult) => {
        // console.log('Next', res)
        if (res.status !== 'MISSING') {
          cancel$.next(true)
        }
      },
      complete: () => {
        // If we have status missing here we have a timeout
        if (this.pdfStatus.status === 'MISSING') {
          this.pdfStatus.status = 'TIMEOUT'
          this.pdfStatus$.next(this.pdfStatus)
        }
      }
    })
  }

  /**
   * Fetch a one time link for the document that sleeps well on S3
   *
   * @param id - The ID of the document
   */
  public getDocumentLink(id: string): Observable<GetDocumentLinkResponse> {
    const url = `${environment.apiUrl}/documents/${id}`
    return this.httpClient.get<GetDocumentLinkResponse>(url).pipe(
      tap((res: GetDocumentLinkResponse) => {
        this.injectedWindow.open(res.signedUrl, '_blank')
      })
    )
  }

  /**
   * Public wrapper for sync. opening the document.
   * Use inte HTML template when subscribe is no fun.
   * @param id
   */
  public openDocument(id: string): void {
    const ref = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Hämtar dokument för utskrift',
        message: ['Tar ett par sekunder eller så...']
      }
    })
    this.getDocumentLink(id).subscribe(
      {
        next: () => {
          setTimeout(() => {
            ref.close()
          }, 4000)
        }
      }
    )
  }

  /**
   * Tries to find a document after we have submitted the create
   * PDF request
   *
   * @param id - The ID created by the create process.
   * @private
   */
  private checkForDocument(id: string): Observable<CheckPDFResult> {
    const delay = 5000
    const url = `${environment.apiUrl}/documents/${id}/check`
    return timer(1000, delay).pipe(
      take(300 * 1000 / delay),
      switchMap(() => {
        return this.httpClient.get<CheckPDFResult>(url)
      })
    )
  }

  private getPlannerState(): MorningstarState {
    if (!this.portfolioPlanner) {
      return {
        accounts: [], goal: [], answers: [],
        portfolioSelection: {portfolioSelectionOption: ''},
        portfolioSelectionAccountSelected: [],
        reportResults: {
          reportInfo:
            {
              reportHeader: {} as any,
              preparedFor: {} as any,
              preparedBy: {} as any
            }
        },
        proposedPortfolio: {
          totalValue: 0,
          holdings: []
        }
      } as MorningstarState
    }
    return this.portfolioPlanner.getVueInstance().state
  }
}
