<div [style.padding.px]="20">
  <h3>{{ data.title }}</h3>
  <div>
    @for (message of data.message; track $index) {
      <p style="white-space: pre-wrap">{{ message }}</p>
    }
  </div>

  @if (data.close) {
    <div>
      <button mat-raised-button matDialogClose="" color="primary">Stäng</button>
    </div>
  }
</div>