<div [style.padding.px]="20">
  <h3>Kund</h3>
  <div>
    <div class="holder">
      <form [formGroup]="form" autocomplete="off">
        <mat-form-field>
          <mat-label>
            @if (corporate) {
              <span>Organisationsnummer</span>
            } @else {
              <span>Personnummer</span>
            }
          </mat-label>
          <input formControlName="sub"
                 matInput
                 type="tel">
        </mat-form-field>
        <mat-checkbox formControlName="corporate" ngDefaultControl>Företag
        </mat-checkbox>
        <mat-form-field>
          <mat-label>
            @if (corporate) {
              <span>Företagsnamn</span>
            } @else {
              <span>Förnamn</span>
            }
          </mat-label>
          <input formControlName="firstName"
                 matInput
                 type="text">
        </mat-form-field>
        @if (!corporate) {
          <mat-form-field>
            <mat-label>Efternamn</mat-label>
            <input formControlName="lastName"
                   matInput
                   type="text">
          </mat-form-field>
        }
        <div class="title">Skapad</div>
        <div>{{ form.controls.created.value | date: 'yyyy-MM-dd HH:mm:ss' }}</div>
      </form>
      <spb-history></spb-history>
    </div>
  </div>

  <div mat-dialog-actions>
    @if (configService.isIrUser$()) {
      <button
        matDialogClose
        [routerLink]="['/', 'morningstar', 'home', form.controls.id.value ]"
        color="accent"
        mat-stroked-button>Starta rådgivning
      </button>
      @if (form.controls.id.value) {
        <button
          (click)="save()"
          [disabled]="!form.valid || !form.dirty"
          color="primary"
          mat-raised-button type="button">Spara
        </button>
      }
    }
    @if (configService.isIrAdmin$() && form.controls.id.value) {
      <button
        matDialogClose
        (click)="delete(form.controls.id.value)"
        color="warn"
        mat-icon-button type="button">
        <mat-icon>delete_outline</mat-icon>
      </button>
    }
    <button
      matDialogClose
      mat-stroked-button
      type="button">Avbryt
    </button>
  </div>
</div>