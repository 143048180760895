import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core'
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatNoDataRow,
  MatRow,
  MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {Customer, CustomerService, IrDocument} from '../../../services/customer.service'
import {MorningstarService} from '../../../services/morningstar.service'
import {MatDialog} from '@angular/material/dialog'
import {DocumentDeleteDialogComponent} from '../document-delete-dialog/document-delete-dialog.component'
import {MatIcon} from '@angular/material/icon'
import {DatePipe} from '@angular/common'
import {filter, first} from 'rxjs/operators'
import {MatPaginator} from '@angular/material/paginator'

export interface ConfirmDeleteData {
  index: number
  documents: IrDocument[]
}

@Component({
  selector: 'spb-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  standalone: true,
  imports: [
    MatTable,
    MatColumnDef,
    MatHeaderCellDef,
    MatHeaderCell,
    MatCellDef,
    MatCell,
    MatIcon,
    MatHeaderRowDef,
    MatHeaderRow,
    MatRowDef,
    MatRow,
    MatNoDataRow,
    DatePipe,
    MatPaginator
  ]
})
export class HistoryComponent implements OnInit, AfterViewInit {

  /**
   * We need our little paginator.
   */
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | null = null

  /**
   * Boilerplate for material tables
   */
  public displayedColumns: string[] = ['created', 'advisor', 'signed', 'delete']

  /**
   * Bla bla table source.
   */
  public dataSource: MatTableDataSource<IrDocument> = new MatTableDataSource<IrDocument>()

  private documents: IrDocument[] = []

  public readonly morningstarService = inject(MorningstarService)
  public readonly customerService = inject(CustomerService)
  private readonly dialog = inject(MatDialog)

  public ngOnInit(): void {
    this.customerService.currentCustomer$.pipe(
      filter(Boolean),
      first()
    ).subscribe({
      next: (customer: Customer) => {
        customer.documents.sort((a, b) => b.created.localeCompare(a.created))
        this.documents = customer.documents
        this.dataSource.data = customer.documents
      }
    })
  }

  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }

  public delete(index: number): void {
    const data: ConfirmDeleteData = {index, documents: this.documents}
    this.dialog.open(DocumentDeleteDialogComponent, {
      data
    })
  }
}
