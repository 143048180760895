import {Component, inject, OnInit} from '@angular/core'
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog'
import {ConfigService} from '../../services/config.service'
import {Customer, CustomerService} from '../../services/customer.service'
import {CommonDeleteDialogComponent} from '../common-delete-dialog/common-delete-dialog.component'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatCheckbox} from '@angular/material/checkbox'
import {HistoryComponent} from './history/history.component'
import {MatButton, MatIconButton} from '@angular/material/button'
import {RouterLink} from '@angular/router'
import {MatIcon} from '@angular/material/icon'
import {DatePipe} from '@angular/common'
import {filter, first, switchMap} from 'rxjs/operators'

@Component({
  selector: 'spb-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatCheckbox,
    HistoryComponent,
    MatDialogActions,
    MatButton,
    MatDialogClose,
    RouterLink,
    MatIconButton,
    MatIcon,
    DatePipe
  ]
})
export class CustomerComponent implements OnInit {

  public customer: Customer = {} as any

  public form = new FormGroup({
    id: new FormControl(),
    sub: new FormControl<string>({
      disabled: true,
      value: ''
    }),
    corporate: new FormControl<boolean>({
      disabled: true,
      value: false
    }, {nonNullable: true}),
    firstName: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    lastName: new FormControl(),
    created: new FormControl(null),
    lastSave: new FormControl(null)
  })

  private readonly dialog = inject(MatDialog)
  protected readonly configService = inject(ConfigService)
  private readonly customerService = inject(CustomerService)


  public ngOnInit(): void {
    this.customerService.currentCustomer$
      .pipe(
        filter(Boolean),
        first()
      )
      .subscribe({
        next: (customer: Customer) => {
          this.customer = customer
          this.form.patchValue(customer)

          Object.values(this.form.controls)
            .filter(() => !this.configService.isIrUser$())
            .forEach((control: AbstractControl) => control.disable())

          if (!this.corporate) {
            this.form.controls.lastName.addValidators(Validators.required)
            this.form.controls.lastName.updateValueAndValidity()
          }
        }
      })
  }

  get corporate(): boolean {
    return this.form.controls.corporate.value
  }

  public save(): void {
    this.customer.firstName = this.form.controls.firstName.value
    this.customer.lastName = this.form.controls.lastName.value
    this.customerService.saveCustomer(this.customer).pipe(
    ).subscribe({
      next: (customer: Customer) => {
        this.customer = customer
        this.form.patchValue(customer)
        this.form.markAsPristine()
      }
    })
  }

  public delete(id: string): void {
    const whatToDelete = `${this.customer.firstName} ${this.customer.lastName}`

    this.dialog.open(CommonDeleteDialogComponent, {data: whatToDelete})
      .afterClosed()
      .pipe(
        filter(Boolean),
        switchMap(() => this.customerService.deleteCustomer(id))
      )
      .subscribe()
  }
}
