<div [style.padding.px]="20">
  <h3>Ny Kund</h3>
  <div>
    <form [formGroup]="form">
      <mat-radio-group (change)="toggle()" formControlName="corporate"
                       ngDefaultControl>
        <mat-radio-button [value]="false">Privat</mat-radio-button>
        <mat-radio-button [value]="true">Företag</mat-radio-button>
      </mat-radio-group>
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>
          @if (corporate) {
            <span>Organisationsnummer</span>
          } @else {
            <span>Personnummer</span>
          }
        </mat-label>
        <input [canBeOrg]="corporate"
               formControlName="sub"
               matInput
               name="personNummer"
               spbPersonnummerValidator
               type="tel">
      </mat-form-field>
      @if (errorMessage) {
        <span class="form-error">{{ errorMessage }}</span>
      }
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>
          <span>{{ corporate ? 'Företagsnamn' : 'Förnamn' }}</span>
        </mat-label>
        <input formControlName="firstName"
               matInput
               name="firstName"
               type="text">
      </mat-form-field>
      @if (!corporate) {
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>Efternamn</mat-label>
          <input formControlName="lastName"
                 matInput
                 name="lastName"
                 type="text">
        </mat-form-field>
      }
    </form>
    <div mat-dialog-actions>
      @if (!exists) {
        <button
          (click)="create()"
          [disabled]="!form.valid"
          [mat-dialog-close]=""
          color="primary"
          mat-raised-button type="button">Skapa
        </button>
      } @else {
        <button
          [mat-dialog-close]=""
          [routerLink]="['/', 'morningstar', 'home', exists ]"
          color="accent"
          mat-stroked-button>Starta rådgivning
        </button>
        <button
          (click)="customerService.open(exists)"
          [mat-dialog-close]=""
          color="accent"
          mat-stroked-button>Hantera
        </button>
      }
      <button [mat-dialog-close]=""
              mat-stroked-button
              type="button">Avbryt
      </button>
    </div>
  </div>
</div>