<div [style.padding.px]="20">
  <h3>Radera</h3>
  <div>Är du säker på att du vill radera {{ whatDoDelete }}?</div>

  <mat-dialog-actions>
    <button
      [matDialogClose]="true"
      color="primary"
      mat-raised-button>Ja
    </button>
    <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
  </mat-dialog-actions>
</div>