import {Component, inject} from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatButton} from '@angular/material/button'

export interface WaitDialogData {
  title: string
  message: string[]
  // Show the close button, default we do not.
  close?: boolean
}

@Component({
  selector: 'spb-wait-dialog',
  templateUrl: './wait-dialog.component.html',
  standalone: true,
  imports: [MatDialogTitle
    , MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class WaitDialogComponent {
  public readonly data: WaitDialogData = inject(MAT_DIALOG_DATA)
}
