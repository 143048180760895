import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {filter, map, switchMap} from 'rxjs/operators'
import {Customer, CustomerService} from '../../services/customer.service'
import {CdkScrollable} from '@angular/cdk/scrolling'
import {MatRadioButton, MatRadioGroup} from '@angular/material/radio'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {MatButton} from '@angular/material/button'
import {RouterLink} from '@angular/router'

@Component({
  selector: 'spb-new-customer',
  templateUrl: './new-customer.component.html',
  styleUrls: ['./new-customer.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, ReactiveFormsModule, MatRadioGroup, MatRadioButton, MatFormField, MatLabel, MatInput, ThemeModule, MatDialogActions, MatButton, MatDialogClose, RouterLink]
})
export class NewCustomerComponent implements OnInit {

  public errorMessage = ''

  public exists = ''

  public form = new FormGroup({
    corporate: new FormControl<boolean | null>(null, Validators.required),
    sub: new FormControl<string | null>({disabled: true, value: null},
      {validators: [Validators.required], nonNullable: true}
      // SpbPersonnummer validator added in template
    ),
    firstName: new FormControl<string | null>({
      disabled: true,
      value: null
    }, [Validators.required]),
    lastName: new FormControl<string | null>({disabled: true, value: null})
  })

  constructor(
    public customerService: CustomerService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
  }

  get corporate(): boolean {
    return !!this.form.controls.corporate.value
  }

  public ngOnInit(): void {
    this.form.controls.sub.valueChanges.pipe(
      filter(Boolean),
      filter(() => {
        if (!this.form.controls.sub.valid) {
          this.errorMessage = `Ogiltigt ${this.form.controls.corporate.value ? 'organisations' : 'person'}nummer`
        }
        return this.form.controls.sub.valid
      }),
      switchMap((sub: string) => {
        return this.customerService.getCustomerBySub(sub)
      }),
      map((customer: Customer | undefined) => {
        if (customer) {
          this.errorMessage = 'Kunden finns redan'
          this.exists = customer.id as string
          return
        }
        this.errorMessage = ''
        this.exists = ''
      })
    ).subscribe()

    // Set Organisations/personnummer and mark control as touched
    this.form.controls.sub.markAsTouched()
    this.form.controls.sub.setValue(this.data.sub)
  }

  public toggle(): void {
    this.form.controls.sub.enable({emitEvent: false})
    this.form.controls.firstName.enable({emitEvent: false})
    this.form.controls.lastName.enable({emitEvent: false})

    if (this.corporate) {
      this.form.controls.lastName.clearValidators()
    } else {
      this.form.controls.lastName.addValidators(Validators.required)
    }
    this.form.controls.lastName.updateValueAndValidity()

    // Update Organisations-personnummer validity after a few milliseconds
    // since template needs to change
    setTimeout(() => {
      this.form.controls.sub.updateValueAndValidity()
    }, 1)
  }

  public create(): void {
    this.customerService.createCustomer(this.form.getRawValue() as any).pipe(
    ).subscribe({
      next: (customer: Customer) => {
        this.customerService.open(customer.id as string)
      }
    })
  }
}
