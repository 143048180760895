import {Component, inject} from '@angular/core'
import {WaitDialogComponent} from '../../wait-dialog/wait-dialog.component'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {CustomerService} from '../../../services/customer.service'
import {ConfirmDeleteData} from '../history/history.component'
import {CdkScrollable} from '@angular/cdk/scrolling'
import {MatButton} from '@angular/material/button'
import {DatePipe} from '@angular/common'

@Component({
  selector: 'spb-confirm-delete-dialog',
  templateUrl: './document-delete-dialog.component.html',
  styleUrls: ['./document-delete-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions, MatButton, MatDialogClose, DatePipe]
})
export class DocumentDeleteDialogComponent {

  private readonly customerService = inject(CustomerService)
  private readonly dialog = inject(MatDialog)
  public readonly dialogRef = inject(MatDialogRef<DocumentDeleteDialogComponent>)
  public readonly data: ConfirmDeleteData = inject(MAT_DIALOG_DATA)

  public delete() {
    const ref = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Tar bort',
        message: ['Ett ögonblick...'],
        close: false
      }
    })
    const id = this.data.documents[this.data.index].id
    this.data.documents.splice(this.data.index, 1)
    this.customerService.deleteDocument(id).subscribe({
      next: () => {
        ref.close()
        this.dialogRef.close()
      }
    })
  }

}
