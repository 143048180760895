import {Component, inject} from '@angular/core'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatButton} from '@angular/material/button'

@Component({
  selector: 'spb-common-delete-dialog',
  templateUrl: './common-delete-dialog.component.html',
  standalone: true,
  styleUrl: './common-delete-dialog.component.scss',
  imports: [MatDialogTitle,
    MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class CommonDeleteDialogComponent {
  public readonly whatDoDelete = inject(MAT_DIALOG_DATA)
}
