/**
 * @description
 *
 * This is a little library designed to handle personnummer.
 * The idea is to make it reusable.
 * Pass a string, and it returns a structure with some interesting
 * data or throws an exception if not a valid personnummer.
 *
 * <i>The code for personnummer check is adapted from
 * https://github.com/personnummer/js when copied
 * March 21st 2019 released under MIT licence</i>.
 *
 * @license Apache-2.0
 * @author Daniel Bergdahl <rexsuecia@gmail.com>
 * @copyright 2022 LINAF AB
 *
 */
var randomNumber = function (min, max) {
  if (min === void 0) {
    min = 0;
  }
  if (max === void 0) {
    max = 9;
  }
  return Math.floor(Math.random() * (max - min)) + min;
};
/**
 * Calculates the expected luhn.
 * @param personNummer - The personnummer without century, separator or luhn,
 * id est 9
 * @return The Luhn check digit.
 */
var getLuhn = function (personNummer) {
  var v = 0;
  var sum = 0;
  for (var i = 0, l = personNummer.length; i < l; i++) {
    v = Number.parseInt(personNummer[i], 10);
    v *= 2 - i % 2;
    if (v > 9) {
      v -= 9;
    }
    sum += v;
  }
  return Math.ceil(sum / 10) * 10 - sum;
};
/**
 * Generates a fake personnummer.
 *
 * @return A valid personnummer
 */
export var generate = function () {
  var centuries = ['19', '19', '19', '20']; // 3 times as likely to get an older person.
  var century = centuries[randomNumber(0, centuries.length)];
  var year = randomNumber(0, century === '19' ? 99 : 24);
  if (year < 10) {
    year = '0' + year;
  }
  var month = randomNumber(1, 12);
  if (month < 10) {
    month = '0' + month;
  }
  var day = randomNumber(1, 28);
  if (day < 10) {
    day = '0' + day;
  }
  year = year + '';
  month = month + '';
  day = day + '';
  var separator = '-';
  var age = calculateAge(Number.parseInt(century + year), Number.parseInt(month), Number.parseInt(day));
  if (age > 100) {
    separator = '+';
  }
  var num = '';
  while (num.length < 3) {
    num += randomNumber();
  }
  var luhn = getLuhn(year + month + day + num + '');
  return century + year + month + day + separator + num + luhn;
};
/**
 * Generates a fake organisationsnummer.
 *
 * @return A valid organisationsnummer
 */
export var generateOrg = function () {
  // Get first number (cannot be 0)
  var nummer = randomNumber(1) + '';
  // Get next 8 digits
  while (nummer.length < 9) {
    // Third digit is always at least 2
    nummer += randomNumber(nummer.length === 2 ? 2 : 0);
  }
  // Get last check digit
  nummer += getLuhn(nummer);
  // Return number with separator between digits 6 and 7
  return "".concat(nummer.substring(0, 6), "-").concat(nummer.substring(6));
};
/**
 * Luhn calculus on a personnummer. Will work with organisationsnummer as well.
 * @param personNummer - The personnummer without century and no separator,
 * e.g. 7309193592
 * @throws Error - Throws an error if it not ok
 */
var checkLuhn = function (personNummer) {
  var check = Number.parseInt(personNummer[9]);
  var luhn = getLuhn(personNummer.substring(0, 9));
  if (luhn !== check) {
    throw new Error("Luhn check failed: ".concat(luhn));
  }
};
/**
 * Test if the supplied numbers actually constitutes a date. If not then
 * it is not a personnummer after all.
 * @param year - As in 1973 or 2011, with century
 * @param month - Should be the natural month as in 1 for January and
 * 9 for September
 * @param day - Day of month, 1 - 31
 * @throws Error - Throws an error if it not ok
 */
var checkDate = function (year, month, day) {
  month -= 1;
  var date = new Date(year, month, day);
  if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
    throw new Error('Not a valid date');
  }
};
/**
 * Calculates the age based on the input year, month a day. Increments
 * year on the day of birth.
 *
 * @param year - The year *with* century e.g. 2024 or 1973
 * @param month - Month, where 1 = January , 12 = December
 * @param day - The day of month 1 - 31
 *
 * @return The age in full years.
 */
var calculateAge = function (year, month, day) {
  var today = new Date();
  var tYear = today.getFullYear();
  var years = tYear - year - 1;
  if (today.getMonth() + 1 > month) {
    years++;
    // I do not understand the sonar issue below! Think the IDE have a hangup.
    // eslint-disable-next-line sonarjs/no-duplicated-branches
  } else if (today.getMonth() + 1 === month && today.getDate() >= day) {
    years++;
  }
  return years;
};
/**
 * Validate a personnummer and return an object with nice representations
 * of the personnummer.
 * @param personNummer - The personnummer to check
 *
 * @return - Returns the personnummer with nice formatting for use in various
 * services.
 *
 * @throws Error - Throws an error if the validation was wrong. Possible options
 * are wrong format, luhn check failure or wrong date.
 */
export var verify = function (personNummer) {
  var reg = /^(\d{2})?(\d{2})(\d{2})(\d{2})([-|+]?)?(\d{3})(\d)$/;
  var match = reg.exec(personNummer.trim());
  if (!match) {
    throw new Error('Not correctly formatted: ' + personNummer);
  }
  var century = match[1] || '';
  var year = match[2];
  var month = match[3];
  var day = match[4];
  var separator = match[5];
  var num = match[6];
  var check = match[7];
  if (separator === undefined) {
    separator = '-';
  }
  if (separator === '+') {
    century = '19';
  }
  // An organisationsnummer is different form a personnummer because the third digit
  // is always between 2 and 9.
  var isOrg = Number(month[0]) >= 2;
  // Make special checks and adjustments for non-organisation personnummers
  var age = -1;
  var birthDay = '';
  if (!isOrg) {
    if (!century) {
      var today = new Date();
      var tYear = today.getFullYear().toString().substring(2, 4);
      century = Number.parseInt(year) < Number.parseInt(tYear) ? '20' : '19';
    }
    // Check if day is samordningsnummer if so use actual date to
    // test for dates where day matters.
    var testDay = Number.parseInt(day);
    if (testDay > 60) {
      testDay = testDay - 60;
    }
    checkDate(Number.parseInt(century + year), Number.parseInt(month), testDay);
    age = calculateAge(Number.parseInt(century + year), Number.parseInt(month), testDay);
    birthDay = month + '/' + testDay;
  }
  // Finally check Luhn, the last digit of the organisations/personnummer
  checkLuhn(year + month + day + num + check);
  return {
    input: personNummer,
    10: year + month + day + num + check,
    11: year + month + day + separator + num + check,
    12: century + year + month + day + num + check,
    13: century + year + month + day + separator + num + check,
    age: age,
    birthDay: birthDay,
    isOrg: isOrg
  };
};
